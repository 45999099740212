import React from 'react';
import {
  ListObjectsCommand,
} from "@aws-sdk/client-s3";
import { bucket, region, s3Client } from './s3Client';
import "./ImageGrid.css";

const command = new ListObjectsCommand({
  Bucket: bucket,
  Prefix: "thumb",
});

class ImageGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {images: []};
  }

  componentDidMount() {
    let client = s3Client();

    client.send(command).then(
      (data) => {
        this.setState({
          images: data.Contents.map((object) => {
            var name = object.Key.match(/\d+/g)[0];
            return {
              name: name,
              thumbSrcURL: `https://${bucket}.s3.${region}.amazonaws.com/${object.Key}`,
              // mediumURL: `https://${bucket}.s3.${region}.amazonaws.com/medium/${name}-medium.jpg`,
            };
          }).reverse(),
        });
      },
      (error) => {
        throw(error);
      }
    );
  }

  render() {
    return (
      <div id="images">
        {this.state.images.map((image, i) => {
          return (
            <a key={i} className="image" href={"/images/" + image.name}>
              <img src={image.thumbSrcURL} />
            </a>
          );
        })}
      </div>
    );
  }
}

export default ImageGrid;
