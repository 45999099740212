import {
  S3Client,
} from "@aws-sdk/client-s3";

// NOTE these are scoped to public read-only for a specific bucket.
const accessKey = "AKIAXI4ZFS2ZAJZB6MJV";
const secretKey = "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";

export const region = "us-east-2";

export const s3Client = () => {
  return new S3Client({
    region: region,
    credentials: {
      accessKeyId: accessKey,
      secretAccessKey: secretKey,
    },
  });
};

export const bucket = "ben-hundley-art";
