import React from 'react';
import { useParams } from "react-router-dom";
import { bucket, region } from './s3Client';
import "./Image.css";

function Image() {
  let { imageName } = useParams();
  let imageURL = `https://${bucket}.s3.${region}.amazonaws.com/medium/${imageName}-medium.jpg`;
  return (
    <div id="image">
      <img src={imageURL} />
    </div>
  );
}

export default Image;
